<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-body">

          <div class="row d-flex align-items-end">
            <div class="form-group col-md-3">
              <label class="col-md-12">Sustancia:</label>
              <treeselect v-model="filtro.substancia_id" :multiple="false" :options="lista_substancias" />
            </div>
            <div class="form-group col-md-3">
              <label>Clase:</label>
              <treeselect v-model="filtro.tipo_classe_id" :multiple="false" :options="lista_tipos_classe" />
            </div>
            <div class="form-group col-md-3">
              <label>Tipo/Presentación:</label>
              <treeselect v-model="filtro.tipo_id" :multiple="false" :options="lista_tipos_produto" />
            </div>
            <div class="form-group col-md-3">
              <label>Laboratorio:</label>
              <treeselect v-model="filtro.laboratorio_id" :multiple="false" :options="lista_laboratorios" />
            </div>
            <div class="form-group col-md-2">
              <label>Listar</label>
              <select v-model="tipo" class="form-control">
                <option value="ativos">Solo productos con estoques ativos</option>
                <option value="todos">Todos los productos</option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Buscar:</label>
              <select v-model="pesquisa.tipo" class="form-control">
                <option value="nome">Por nombre</option>
                <option value="codigo_barra">Por codigo de barras</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="text">Filtro</label>
              <input v-model="filtro.texto" type="text" class="form-control" id="text" placeholder />
            </div>
            <div class="form-group col-md-1">
              <button class="btn btn-success" @click="listar_produtos()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-11 pt-3">
      <div class="card full-height bg-ligth">

        <div class="card-body">

          <b-table responsive :fields="[
            'nome',
            'tipo_produto',
            'descricao',
            'laboratorio',
            'codigo_barra',
            'fracao',
           
            'filial_fracao',
            'filial_quantidade',
            'quantidade_geral',
            'fracao_geral',
            'acoes',
          ]" :items="lista_produtos" :per-page="perPage" :current-page="currentPage" id="produto-table" class="
                        table
                        table-responsive
                        table-head-custom
                        table-vertical-center
                        table-head-bg
                        table-borderless
                      " show-empty empty-text="Nenhum registro encontrado!">
            <template #head(acoes)><span></span></template>
            <template #head(nome)>Nombre</template>
            <template #head(descricao)>Descripción</template>
            <template #head(tipo_produto)>Presentación</template>
            <template #head(fracao)>Fracción</template>
            <template #head(filial_fracao)>Fraccón filial</template>
            <template #head(filial_quantidade)>Cant. filial</template>
            <template #head(fracao_geral)>Fracción General</template>
            <template #head(quantidade_geral)>Cant. General</template>
        
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button v-show="lista_permissoes_filial.u_Produto" @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                  <i class="far fa-eye text-primary"></i>
                </button>

                <button v-show="lista_permissoes_filial.d_Produto" @click="confirm('ELIMINARA', item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Excluir registro">
                  <!-- <i class="fas fa-unlock-alt text-danger"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-trash text-danger" viewBox="0 0 16 16">
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_produtos.length" :per-page="perPage"
            aria-controls="produto-table">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import console from "console";
export default {
  mixins: { fireAlert },
  data() {
    return {
      pesquisa: {
        tipo: null,
        texto: "",
      },
      tipo: "",
      currentPage: 1,
      perPage: 10,
      filtro: {
        substancia_id: null,
        tipo_classe_id: null,
        laboratorio_id: null,
        tipo_id: null,
        texto: null,
        tipo:null,
      }
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Productos" }]);
  },
  created() {
    this.listar_produtos()
    this.listar_filtros()
  },
  watch: {
    tipo() {
      if ((this.tipo = "ativos")) this.listar_produtos2();
      else this.listar_produtos();
    },
    'filtro.texto'(value) {
      if (value.length > 3) this.listar_produtos()
    },
  },

  computed: {
    lista_produtos() {
      return this.$store.state.produto.lista_produtos.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_tipos_classe() {
      return this.$store.state.tipoClasse.lista_tipos_classe;
    },
    lista_substancias() {
      return this.$store.state.substancia.lista_substancias;
    },
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },
    lista_laboratorios() {
      return this.$store.state.laboratorio.lista_laboratorios;
    },
  },
  methods: {
    async listar_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/listar_produtos_filtro", this.filtro).finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
      });
    },
    async listar_filtros() {
      await this.$store.dispatch("substancia/listar_substancias")
      await this.$store.dispatch("tipoClasse/listar_tipos_classe")
      await this.$store.dispatch("produto/listar_tipos_produto")
      await this.$store.dispatch("laboratorio/listar_laboratorios")
    },
    async listar_produtos2() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("produto/listar_produtos_tipo", this.tipo)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          // this.$store.dispatch("produto/listar_produtos_tipo",this.tipo);
          // localStorage.removeItem('produto');
        });
    },
    atualizar(value) {
      this.$router.push({
        name: "produtoDados",
        params: { produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/pesquisar_produto", this.pesquisa);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async confirm(text, value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta accion ` + text + ` este produto del sistema. Verifique si existem estoques activos antes de eliminar `,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.excluir(value);
          }
        },
      });
    },
    async excluir(value) {
      await this.$store.dispatch("produto/excluir_produto", value.id);
      console.log(this.mensagem_alert)
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
      await this.$store.dispatch("produto/listar_produtos_filtro", this.filtro)
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>